<template>
    <v-app>
        <v-navigation-drawer v-if="logged" persistent
                             :mini-variant="miniVariant"
                             :clipped="clipped"
                             v-model="drawer"
                             enable-resize-watcher
                             fixed
                             app>
            <v-list>
                <v-list-item value="true" to="/iscritti">
                    <v-list-item-action>
                        <v-icon color="pink">mdi-format-columns</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Iscritti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item value="true" to="/gruppi">
                    <v-list-item-action>
                        <v-icon color="brown">mdi-select-group</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Gruppi</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item value="true" to="/vita">
                    <v-list-item-action>
                        <v-icon color="blue-grey">mdi-square-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Ad. Infrasettimanale</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item value="true" to="/discorsi">
                    <v-list-item-action>
                        <v-icon color="green">mdi-view-grid</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Ad. Pubblica</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item value="true" to="/comitive">
                    <v-list-item-action>
                        <v-icon color="info">mdi-door-closed</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Ad. Servizio di campo</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item value="true" to="/testimonianza">
                    <v-list-item-action>
                        <v-icon color="info">mdi-home-city</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Test. Pubblica</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item value="true" to="/incarichi">
                    <v-list-item-action>
                        <v-icon color="orange">mdi-account-box-multiple-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Incarichi</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item value="true" to="/docviewer">
                    <v-list-item-action>
                        <v-icon color="teal">mdi-calendar</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Pulizie</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!--
    <v-list-item value="true" to="/dashboard">
        <v-list-item-action>
            <v-icon color="blue">mdi-monitor-dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
        -->
            </v-list>
        </v-navigation-drawer>

        <v-app-bar v-if="logged" app :clipped-left="clipped" color="darkgrey" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-btn class="d-none d-lg-flex" icon @click.stop="miniVariant = !miniVariant">
                <v-icon v-html="miniVariant ? 'mdi-chevron-right' : 'mdi-chevron-left'"></v-icon>
            </v-btn>
            <v-img class="rounded-lg mr-2" src="@/assets/board_icon.png"
                   max-width="55">
            </v-img>
            <v-toolbar-title>
                    Board - {{this.$store.state.congregazioneCorrente.nome}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch v-model="theme"
                      @click="switchTheme"
                      persistent-hint
                      :hint="theme ? 'scuro':'chiaro'"></v-switch>
            <template>
                <div class="text-center">
                    <v-menu open-on-hover offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-container v-bind="attrs"
                                         v-on="on">
                                <v-list-item-title>{{nominativo}}</v-list-item-title>
                                <v-list-item-subtitle class="text-subtitle grey--text">{{congregazione}}</v-list-item-subtitle>

                            </v-container>
                        </template>

                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{nominativo}}</v-list-item-title>
                                    <v-list-item-subtitle>{{congregazione}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>

                        <v-list>
                            <v-list-item @click="logout">
                                <v-list-item-action>
                                    <v-icon>mdi-logout</v-icon>
                                </v-list-item-action>
                                <v-list-item-title>Logout</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>

        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>

        <v-footer v-if="logged" app>
            <span class="text-body-2">&nbsp;Gigios Soft &nbsp;&copy;&nbsp;2022</span>
            <v-spacer></v-spacer>
            <span class="text-body-2"><a href="#" style="text-decoration: none;" @click="privacy=true">Privacy e Dati</a></span>
        </v-footer>
        <v-dialog v-model="privacy" persistent max-width="850px">
            <v-card class="elevation-24 rounded-lg">
                <v-card-title>Informativa sulla Privacy e la gestione dei Dati</v-card-title>
                <v-card-text>
                    <br />
                    <v-row class="mx-1">
                        <p><b>Dati: </b>Utilizzando l'app Talk e il portate talkapp.it presti il consenso affinché i tuoi dati siano condivisi dall'amministratore della circoscrizione e dai coordinatori dei discorsi.</p>
                        <p>Nominativo, numero di telefono, indirizzo email ed elenco degli schemi posseduti sono utilizzati dai coordinatori al fine di stilare il programma dei discorsi e agevolarne la gestione. </p>
                        <p>Nessun dato verrà fornito in alcun modo a terzi o utilizzato a fini promozionali o pubblicitari.</p>
                        <p><b>Note tecniche: </b> Lapp condivide le informazioni tra i vari coordinatori e oratori nella circoscrizione in modo da avere immediata sincronizzazione. A questo scopo i dati sono memorizzati sul cloud di MongoDB (https://www.mongodb.com) che è una soluzione professionale ed efficiente molto utilizzata nell'ingegneria del software. L'intero database è criptato e protetto da password con tutti i criteri di sicurezza più recenti.</p>
                        <p>L’admin della circoscrizione ha comunque la possibilità facendo tap su un tasto nell’app di rimuovere tutti i dati i maniera definitiva.</p>
                        <p><b>Cookies: </b>Sono utilizzati per memorizzare alcune preferenze di visualizzazione del portale (esempio il tema chiaro/scuro). Non viene effettuata alcuna profilazione utente per fini commerciali. </p>
                        <p><b>Costi: </b>L'app è gratuita, non prevede alcun tipo di pubblicità e i costi di sviluppo software, di dominio e hosting e di mantenimento dell'app negli store non saranno mai richiesti agli utenti.</p>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" @click.stop="privacy = false">Ok</v-btn>                 
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>

</template>

<script>

    import { callService, callPost, euro, gLocalData, gCheckData, gServerData, Snack, SUPERADMIN_USERNAME } from '@/modules/utilities.js'

    export default {
        data() {
            return {
                clipped: true,
                drawer: true,
                miniVariant: false,
                right: true,
                title: 'Talk',
                SUPERADMIN_USERNAME:'',
                theme: false,
                privacy: false
            }
        },
        name: 'App',
        computed: {            
            logged() {
                return this.$store.state.logged
            },
            nominativo() {
                return this.$store.state.iscritto.nominativo;
            },
            congregazione() {
                return this.$store.state.congregazione.nome;
            }            
        },
        methods: {      
            logout() {
                this.$store.dispatch('logout');
                this.$store.state.oratore= '';
                this.$router.replace({ name: "login" });
            },
            switchTheme() {
                this.$vuetify.theme.dark = this.theme;
                this.$cookie.set('theme', this.theme,  { expires: '1Y' });
            }
        },
        beforeCreate(){
           
        },
        created(){
            this.SUPERADMIN_USERNAME = SUPERADMIN_USERNAME;
            let theme = this.$cookie.get('theme')=="true"; //trasformo da string a bool
            this.theme = theme;
            this.$vuetify.theme.dark = theme;
        },
        mounted() {
            if (!this.$store.state.logged) {
                this.$router.replace({ name: "login" });
            }
        }
    }</script>

<style>
    table.v-table tbody td {
        font-size: 28px !important;
    }
</style>


