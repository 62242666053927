<template>
    <v-container>       
        <v-row dense>
            <v-col cols="8">
                <v-row dense>
                    <v-col cols="6">
                        <v-card class="ma-2" :color="$vuetify.theme.dark ? 'gray' :'orange lighten-5'">
                            <v-card-title>
                                <v-icon x-large left>
                                    mdi-chart-timeline-variant
                                </v-icon>
                                <span class="text-h6 font-weight-light">
                                    Ruoli App
                                </span>
                            </v-card-title>
                            <v-card-text>
                                <v-progress-linear v-if="loadingRuoli"
                                                   indeterminate
                                                   reverse></v-progress-linear>
                                <GChart v-if="!loadingRuoli" type="ColumnChart"
                                        :data="dashboard.graficoRuoli"
                                        :options="chartOptionsColumn2"
                                        :settings="{language: 'it'}" />
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card class="ma-2" :color="$vuetify.theme.dark ? 'gray' :'orange lighten-5'">
                            <v-card-title>
                                <v-icon x-large left>
                                    mdi-chart-timeline-variant
                                </v-icon>
                                <span class="text-h6 font-weight-light">
                                    Nomine
                                </span>
                            </v-card-title>
                            <v-card-text>
                                <v-progress-linear v-if="loadingNomine"
                                                   indeterminate
                                                   reverse></v-progress-linear>
                                <GChart v-if="!loadingNomine" type="ColumnChart"
                                        :data="dashboard.graficoNomine"
                                        :options="chartOptionsColumn1"
                                        :settings="{language: 'it'}" />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-card class="ma-2" :height="h+100" :color="$vuetify.theme.dark ? 'gray' :'orange lighten-5'">
                            <v-card-title>
                                <v-icon x-large left>
                                    mdi-chart-timeline-variant
                                </v-icon>
                                <span class="text-h6 font-weight-light" v-html="'Numero Appuntamenti per '+(ora || !allCong ? 'Oratore':'Congregazione')" />
                                <v-spacer></v-spacer>
                                <v-switch v-if="allCong && !all" v-model="ora" :hint="ora ? 'oratori':'congregazioni'" @click="loadProgrammi" persistent-hint></v-switch>
                                <v-spacer></v-spacer>
                                <v-switch v-model="futuri" :hint="futuri ? 'futuri':'ultimo anno'" @click="loadProgrammi" persistent-hint></v-switch>
                            </v-card-title>
                            <v-card-text class="ma-0">
                                <v-progress-linear v-if="loadingProgrammi"
                                                   indeterminate
                                                   reverse></v-progress-linear>
                                <GChart v-if="!loadingProgrammi" type="BarChart"
                                        :data="dashboard.graficoProgrammi"
                                        :options="chartOptionsBar"
                                        :settings="{language: 'it'}" />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
           </v-col>
            <v-col cols="4">
                <v-card class="ma-2" :color="$vuetify.theme.dark ? 'gray' :'orange lighten-5'">
                    <v-card-title>
                        <v-icon x-large left>
                            mdi-chart-timeline-variant
                        </v-icon>
                        <span class="text-h6 font-weight-light">
                            Ultimi utenti connessi
                        </span>
                        <v-spacer></v-spacer>
                        <v-switch v-model="web" :hint="web ? 'web app':'app'" @click="loadOratori" persistent-hint></v-switch>
                    </v-card-title>
                    <v-card-text>
                        <v-progress-linear v-if="loadingOratori"
                                           indeterminate
                                           reverse></v-progress-linear>
                        <v-data-table v-if="!loadingOratori" class="my-2" style="background-color:transparent"
                                      :loading="loadingOratori"
                                      :headers="headersOratori"
                                      :items="dashboard.listaOratori"
                                      :items-per-page="100"
                                      loading-text=""
                                      no-data-text="Nessun oratore presente"
                                      hide-default-header
                                      hide-default-footer
                                      dense>
                            <template v-slot:item.nominativo="{ item }">
                                <span class="text-caption">{{item.nominativo}}</span>
                                <br />
                                <span class="text-caption font-weight-light">{{item.congregazione}}</span><span v-if="$store.state.iscritto.username==SUPERADMIN_USER" class="text-caption font-weight-light"> ({{item.circoscrizione}})</span>
                            </template>
                            <template v-slot:item.data="{ item }">
                                <span class="text-caption" v-html="localDataTime(item.data)" />
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>import { callService, callPost, euro, gLocalData, gLocalDataTime, gCheckData, gServerData, gDataCalendar, Snack, SUPERADMIN_USERNAME} from '@/modules/utilities.js'
    import { bus } from '@/main.js'
    import { GChart } from 'vue-google-charts/legacy'

    export default {
       
        data: () => ({         
            SUPERADMIN_USERNAME: '',
            loadingRuoli: false,
            loadingNomine: false,
            loadingOratori: false,
            loadingProgrammi: false,
            dashboard: {},
            web: false,
            all: false,
            allCong: false,
            ora: false,
            futuri: false,
            h: 600,
            h1: 600,
            h2: 400,
            chartData: [],
            headersOratori: [
                {
                    text: 'Nominativo',
                    align: 'start',
                    value: 'nominativo',
                },
                { text: 'Data Connessione', value: 'data', sortable: false },
            ],
        }),
        components: { GChart },
        computed: {            
            chartOptionsColumn1() {
                return {
                    legend: { position: 'none' },
                    backgroundColor: { fill: 'transparent' },
                    colors: ['orange'],
                    vAxis: {
                        textStyle: {
                            color: this.$vuetify.theme.dark ? 'white' : 'black'
                        }
                    },
                    hAxis: {
                        textStyle: {
                            color: this.$vuetify.theme.dark ? 'white' : 'black'
                        }
                    }
                }
            },
            chartOptionsColumn2() {
                return {
                    legend: { position: 'none' },
                    backgroundColor: { fill: 'transparent' },
                    colors: ['brown'],
                    vAxis: {
                        textStyle: {
                            color: this.$vuetify.theme.dark ? 'white' : 'black'
                        }
                    },
                    hAxis: {
                        textStyle: {
                            color: this.$vuetify.theme.dark ? 'white' : 'black'
                        }
                    },
                    format: '0'
                }
            },
            chartOptionsBar() {
                return {
                    height: this.h,
                    legend: { position: 'none' },
                    backgroundColor: { fill: 'transparent' },
                    vAxis: {
                        textStyle: {
                            fontSize: 9, 
                            color: this.$vuetify.theme.dark ? 'white' : 'black'
                        }
                    },
                    hAxis: {
                        textStyle: {
                            color: this.$vuetify.theme.dark ? 'white' : 'black'
                        },
                        format: '0'
                    },
                    chartArea: { 'width': '60%', 'height': '90%' }
                }
            },
            superAdmin() {
                return this.$store.state.iscritto.username == SUPERADMIN_USERNAME;
            }
        },
        methods: {
            localDataTime(v) {
                return gLocalDataTime(v);
            },
            async loadRuoli() {
                this.loadingRuoli = true;
                let param = [this.$store.state.idCircoscrizione, this.$store.state.congregazioneCorrente.coId, this.all, this.allCong];
                this.dashboard.graficoRuoli = await callService("api/dashboard/ruoli", param);
                this.loadingRuoli = false;
            },
            async loadNomine() {
                this.loadingNomine = true;
                let param = [this.$store.state.idCircoscrizione, this.$store.state.congregazioneCorrente.coId, this.all, this.allCong];
                this.dashboard.graficoNomine = await callService("api/dashboard/nomine", param);
                this.loadingNomine = false;
            },
            async loadOratori() {
                this.loadingOratori = true;
                let param = [this.$store.state.idCircoscrizione, this.$store.state.congregazioneCorrente.coId, this.all, this.allCong, this.web];
                this.dashboard.listaOratori = await callService("api/dashboard/oratori", param);
                this.loadingOratori = false;
            },
            async loadProgrammi() {
                this.loadingProgrammi = true;
                let param = [this.$store.state.idCircoscrizione, this.$store.state.congregazioneCorrente.coId, this.all, this.allCong, this.ora, this.futuri];
                this.dashboard.graficoProgrammi = await callService("api/dashboard/programmi", param);
                this.h = (this.dashboard.graficoProgrammi.length> 15 ? this.h1 : this.h2);
                this.loadingProgrammi = false;
            },
            loadAll() {
                if(this.all) {this.ora=false;this.allCong=true;}                
                this.loadRuoli();
                this.loadNomine();
                this.loadOratori();
                this.loadProgrammi();
            } 
        },        
        created() {
            this.SUPERADMIN_USERNAME = SUPERADMIN_USERNAME;
            //this.loadAll();
        } 

    }</script>
