import Vue from 'vue';
import App from './App.vue';
import Router from './router';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import Store from './store/store'
import VueCookie from 'vue-cookie'

Vue.config.productionTip = false;

Vue.use(Vuetify);
Vue.use(VueCookie);

export const bus = new Vue();

new Vue({
    vuetify: new Vuetify(),
    router: Router,
    store: Store,
    render: h => h(App),
}).$mount('#app')