import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login.vue"
import Iscritti from "../views/Iscritti.vue"
import Vita from "../views/Vita.vue"
import Incarichi from "../views/Incarichi.vue"
import Comitive from "../views/Comitive.vue"
import Testimonianza from "../views/Testimonianza.vue"
import Dashboard from "../views/Dashboard.vue"
import Gruppi from "../views/Gruppi.vue"


Vue.use(VueRouter)

export default new VueRouter({
    routes: [        
        {
            path: "/login",
            name: "login",
            component: Login
        },              
        
        {
            path: "/iscritti",
            name: "iscritti",
            component: Iscritti
        },
        {
            path: "/gruppi",
            name: "gruppi",
            component: Gruppi
        },
        {
            path: "/vita",
            name: "vita",
            component: Vita
        },        
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard
        },        
        {
            path: "/incarichi",
            name: "incarichi",
            component: Incarichi
        },
        {
            path: "/comitive",
            name: "comitive",
            component: Comitive
        },
        {
            path: "/testimonianza",
            name: "testimonianza",
            component: Testimonianza
        }
    ],
    mode: 'history'
})