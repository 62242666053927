<template>
    <v-dialog v-model="dialog" persistent max-width="1200px">
        <v-card class="elevation-24 rounded-lg">
            <v-card-title></v-card-title>
            <v-card-text>
                <v-form v-if="dettagli.materiale && dettagli.parti" :readonly="!modificheAbilitate" v-model="valid" ref="formVita">
                    <v-row>
                        <v-col cols="2">
                            <v-text-field v-model="programma.data"
                                          outlined
                                          autofocus
                                          type="date"
                                          label="Data"
                                          :rules="[v=> !!v || 'campo obbligatorio', warningDuplicati==null || 'Duplicazioni presenti']"
                                          dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete v-model="dettagli.parti[0].parte.iscritto.id"
                                            :items="dettagli.parti[0].iscrittiEstesi"
                                            chips
                                            no-data-text="Nessun iscritto trovato"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            :label="dettagli.parti[0].parte.tipoParte.descrizione"
                                            :placeholder="dettagli.parti[0].parte.tipoParte.descrizione"
                                            item-text="iscritto.nominativo"
                                            item-value="iscritto.id">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ data.item.iscritto.nominativo }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="data.item.oggi" class="red--text text-caption font-weight-strong" v-html="'Oggi: '+data.item.oggi"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete v-model="dettagli.parti[1].parte.iscritto.id"
                                            :items="dettagli.parti[1].iscrittiEstesi"
                                            chips
                                            no-data-text="Nessun iscritto trovato"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            :label="dettagli.parti[1].parte.tipoParte.descrizione"
                                            :placeholder="dettagli.parti[1].parte.tipoParte.descrizione"
                                            item-text="iscritto.nominativo"
                                            item-value="iscritto.id">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ data.item.iscritto.nominativo }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="data.item.oggi" class="red--text text-caption font-weight-strong" v-html="'Oggi: '+data.item.oggi"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete v-model="dettagli.parti[3].parte.iscritto.id"
                                            :items="dettagli.parti[3].iscrittiEstesi"
                                            chips
                                            no-data-text="Nessun iscritto trovato"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            :label="dettagli.parti[3].parte.tipoParte.descrizione"
                                            :placeholder="dettagli.parti[3].parte.tipoParte.descrizione"
                                            item-text="iscritto.nominativo"
                                            item-value="iscritto.id">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ data.item.iscritto.nominativo }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="data.item.oggi" class="red--text text-caption font-weight-strong" v-html="'Oggi: '+data.item.oggi"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete v-model="dettagli.parti[5].parte.iscritto.id"
                                            :items="dettagli.parti[5].iscrittiEstesi"
                                            chips
                                            no-data-text="Nessun iscritto trovato"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            :label="dettagli.parti[5].parte.tipoParte.descrizione"
                                            :placeholder="dettagli.parti[5].parte.tipoParte.descrizione"
                                            item-text="iscritto.nominativo"
                                            item-value="iscritto.id">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ data.item.iscritto.nominativo }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="data.item.oggi" class="red--text text-caption font-weight-strong" v-html="'Oggi: '+data.item.oggi"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete v-model="dettagli.parti[6].parte.iscritto.id"
                                            :items="dettagli.parti[6].iscrittiEstesi"
                                            chips
                                            no-data-text="Nessun iscritto trovato"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            :label="dettagli.parti[6].parte.tipoParte.descrizione"
                                            :placeholder="dettagli.parti[6].parte.tipoParte.descrizione"
                                            item-text="iscritto.nominativo"
                                            item-value="iscritto.id">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ data.item.iscritto.nominativo }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="data.item.oggi" class="red--text text-caption font-weight-strong" v-html="'Oggi: '+data.item.oggi"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete v-model="dettagli.parti[7].parte.iscritto.id"
                                            :items="dettagli.parti[7].iscrittiEstesi"
                                            chips
                                            no-data-text="Nessun iscritto trovato"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            :label="dettagli.parti[7].parte.tipoParte.descrizione"
                                            :placeholder="dettagli.parti[7].parte.tipoParte.descrizione"
                                            item-text="iscritto.nominativo"
                                            item-value="iscritto.id">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ data.item.iscritto.nominativo }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="data.item.oggi" class="red--text text-caption font-weight-strong" v-html="'Oggi: '+data.item.oggi"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete v-model="dettagli.parti[8].parte.iscritto.id"
                                            :items="dettagli.parti[8].iscrittiEstesi"
                                            chips
                                            no-data-text="Nessun iscritto trovato"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            :label="dettagli.parti[8].parte.tipoParte.descrizione"
                                            :placeholder="dettagli.parti[8].parte.tipoParte.descrizione"
                                            item-text="iscritto.nominativo"
                                            item-value="iscritto.id">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ data.item.iscritto.nominativo }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="data.item.oggi" class="red--text text-caption font-weight-strong" v-html="'Oggi: '+data.item.oggi"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="modificheAbilitate" :disabled="!valid" color="error" @click="save">Salva</v-btn>
                <v-btn color="warning" :loading="loading" @click.stop="dialog = false">Chiudi</v-btn>
                <v-btn v-if="!fileDoc" color="success" :loading="loadingDoc" @click="generaDoc">Genera Programma</v-btn>
                <v-btn v-if="fileDoc" color="success" @click="downloadDoc">Scarica Programma</v-btn>
                <v-btn v-if="fileDoc" color="success" @click="linkViewerDocument">Apri Programma</v-btn>
                <v-spacer></v-spacer>
                <v-btn  color="red" dark :loading="loadingPromo" @click="promemoria">Invia Promemoria</v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackInfo"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Credenziali copiate in memoria.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-dialog>
</template>

<script>
    import {STD_PASSWORD, SUPERADMIN_USERNAME, callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack} from '@/modules/utilities.js'

    import { bus } from '@/main.js'

    export default {
        data() {
            return {
                dialog: false,
                programma: {},
                sesso: '0',
                valid: false,   
                snackInfo: false,
                snackSuccess: false,
                snackError: false,
                snackCancel: false,
                snackRemote: false,
                SUPERADMIN_USERNAME: '',
                loading:false,
                dettagli: {},
                message:'',
                data:null,
                tab:null,
                fileDoc:'',
                loadingDoc: false,
                loadingPromo: false,
                linkStaticGoogle: 'https://docs.google.com/viewerng/viewer?url=https://board.talkapp.it/',
                linkStaticMicrosoft: 'https://view.officeapps.live.com/op/embed.aspx?src=https://board.talkapp.it/'
            }
        },
        methods: {
            async save() {
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.iscritto.username,
                    token: this.$store.state.iscritto.token,
                    idCongregazione: this.$store.state.congregazioneCorrente.partitionReference,
                    tag: "INC",
                    programma: this.programma,
                    materiale: this.dettagli.materiale,
                    parti: this.dettagli.parti
                };
                let programma = await callPost('api/programma/save', paramJson);                

                if (programma) this.snackSuccess = true;
                else this.snackError = true;

                if(programma) {

                    bus.$emit('dialog-update-incarichi', programma);
                   
                    //ricarico

                    bus.$emit('dialog-show-incarichi',programma);
                }                
            },                
            filterFunction(item, queryText) {
                return (                    
                    (item.diId+"").toLowerCase().includes(queryText.toLowerCase()) ||  
                    item.tema.toLowerCase().includes(queryText.toLowerCase())
                  );
            },
            dataCalendar(v) {
                return gDataCalendar(v);
            },
            localData(v) {
                return gLocalData(v);
            },
            async promemoria() {
                this.loadingPromo=true;
                let param = [this.$store.state.congregazioneCorrente.partitionReference, this.programma.id];
                let output = await callService("api/mail/weekly", param);
                if(output) {
                    this.snackSuccess=true;
                }
                else this.snackError=true;
                this.loadingPromo=false;
            },
            async generaDoc() {
                this.loadingDoc=true;
                let param = [this.$store.state.congregazioneCorrente.partitionReference, this.programma.id];
                let output = await callService("api/doc/genericDoc", param);
                if(output) {
                    this.fileDoc = output.fileName;
                }
                else this.snackError=true;
                this.loadingDoc=false;
            },
            downloadDoc(){
                window.open(this.fileDoc,'_blank');
                this.fileDoc=''; 
            },
            linkViewerDocument(){
                window.open(this.linkStaticGoogle + this.fileDoc, '_blank');

                //window.open(this.linkStaticMicrosoft + this.fileDoc, '_blank');
                this.fileDoc='';                
            }
        },
        computed: {     
            modificheAbilitate() {
                if(this.programma.id == "0") return true;
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                else if(this.$store.state.iscritto.isAdmin) b=true;

                return b;
            },
            isAdmin() {
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                else if(this.$store.state.iscritto.isAdmin) b = true;

                return b;
            },
            isSuperAdmin() {
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                return b;
            },
            warningDuplicati() {
                if(!this.dettagli.parti) return null;

                for(let i=0;i<=7;i++) {
                    console.log(this.dettagli.parti[i].parte.iscritto.nominativo);
                    for(let j=i+1;j<=8;j++) {                   

                        if( this.dettagli.parti[i].parte.iscritto
                         && this.dettagli.parti[j].parte.iscritto
                         && this.dettagli.parti[i].parte.iscritto.id
                         && this.dettagli.parti[j].parte.iscritto.id
                         && this.dettagli.parti[i].parte.iscritto.id!='0'
                         && this.dettagli.parti[j].parte.iscritto.id!='0'
                         && this.dettagli.parti[i].parte.iscritto.id == this.dettagli.parti[j].parte.iscritto.id) {
                            return "presenza di duplicati";
                        }
                    }
                }                   
    
                return null;
            }
        },
        async created() {
            this.SUPERADMIN_USERNAME = SUPERADMIN_USERNAME;
            var vm = this;
            bus.$on('dialog-show-incarichi', async function (programma) {
                vm.dialog = true;
                vm.programma = programma;
                vm.loading = true;

                if (programma.id == '0') programma.data = new Date();
                else programma.data = vm.dataCalendar(programma.data);

                let param = [vm.$store.state.congregazioneCorrente.partitionReference, programma.id,"INC"];
                vm.dettagli = await callService("api/programma/dettagli", param);
                                
                vm.fileDoc ='';
                vm.loading = false;
                
            });            
        },        
        beforeDestroy() {
            bus.$off('dialog-show-incarichi');
        }
    }
</script>