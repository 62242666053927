<template>
    <v-container>
        <template>
            <v-data-table :loading="loadingProgrammi"
                          :headers="headersProgrammi"
                          :items="programmi"
                          :items-per-page="20"
                          loading-text="Caricamento dati..."
                          no-data-text="Nessuno programma presente"
                          no-results-text="Nessun programma corrispondente ai criteri di ricerca"
                          :search="search"
                          :footer-props="{
                    'items-per-page-text':'Pagina da',
                    'items-per-page-all-text': 'Tutti',
                    'items-per-page-options':[5,8,10,20,-1]
                    }"
                          class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title><b>Testimonianza Pubblica</b></v-toolbar-title>                        
                        <v-col cols="3">
                            <v-text-field class="mt-6 ml-4"
                                          v-model="dataIniziale"
                                          label="Data iniziale"
                                          single-line
                                          type="date"
                                          hint="data di partenza"
                                          persistent-hint
                                          @change="ricalcola"
                                          outlined
                                          clearable
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-tooltip v-if="isAdmin" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon x-large
                                       color="info"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="nuovoProgramma">
                                    <v-icon>mdi-view-grid-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Nuova Data</span>
                        </v-tooltip>
                    </v-toolbar>
                </template>
                <template v-slot:item.giorno="{ item }">                   
                    <span>{{item.programma.giorno}}</span><br />
                    <span class="text-caption">{{item.ore}}</span>
                </template>
                <template v-slot:item.data="{ item }">
                    <span v-html="localData(item.programma.data)"></span>
                </template>
                <template v-slot:item.dettaglio="{ item }">
                    <div>
                        <v-icon @click="clickProgramma(item)">mdi-magnify</v-icon>
                    </div>
                </template>
                <template v-slot:item.duplica="{ item }">
                    <div>
                        <v-icon @click="duplicaProgramma(item)">mdi-content-copy</v-icon>
                    </div>
                </template>
                <template v-slot:item.elimina="{ item }">
                    <div>
                        <v-icon @click="eliminaProgramma(item)">mdi-trash-can-outline</v-icon>
                    </div>
                </template>
            </v-data-table>

            <testimonianza-dialog v-model="dialogProgramma" editIscritto="programma" />

        </template>
    </v-container>
</template>

<script>

    import { callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, gApiData, Snack, SUPERADMIN_USERNAME} from '@/modules/utilities.js'
    import TestimonianzaDialog from '@/views/components/TestimonianzaDialog.vue'
    import { bus } from '@/main.js'

    export default {
        components:{
            TestimonianzaDialog
        },
        data: () => ({            
            headersProgrammi: [
                { text: 'Giorno', align: 'start', value: 'giorno' },
                { text: 'Data', align: 'start', value: 'data' },
                { text: 'Tag Test. Pub.', align: 'start', value: 'programma.tag' },
                { text: 'Proclamatore 1', align: 'start', value: 'proclamatore1.nominativo' },
                { text: 'Proclamatore 2', align: 'start', value: 'proclamatore2.nominativo' },
                { text: '', align: 'start', value: 'dettaglio'},
                { text: '', align: 'start', value: 'duplica'},
                { text: '', align: 'start', value: 'elimina'},
            ],
            programmi: [],
            dialogProgramma: false,
            iscritto: {},
            loadingProgrammi:false,
            SUPERADMIN_USERNAME: '',
            isAdmin: false,
            search: '',
            programma: {},
            dataIniziale: null
        }),
        computed: {
        },
        methods: {
            async clickProgramma(item) {
                this.programma = JSON.parse(JSON.stringify(item.programma));
                bus.$emit('dialog-show-tes', this.programma);
            },
            async duplicaProgramma(item) {
                let param = [this.$store.state.congregazioneCorrente.partitionReference, item.programma.id];
                let programma = await callService("api/programma/copia", param);
                bus.$emit('dialog-update-tes', programma);
            },
            async eliminaProgramma(item) {
                const i = this.programmi.findIndex(o => o.programma.id == item.programma.id);
                let param = [this.$store.state.congregazioneCorrente.partitionReference, item.programma.id];
                let ok = await callService("api/programma/elimina", param);
                if(ok) {
                    this.programmi.splice(i, 1);
                }
            },
            async onUpdateProgramma(programma) {
                const i = this.programmi.findIndex(o => o.programma.id == programma.id);
                let param = [this.$store.state.congregazioneCorrente.partitionReference];
                let programmi = await callService("api/programma/listaTestimonianza", param);
                const j = programmi.findIndex(o => o.programma.id == programma.id);
                if(i>=0 && j>=0)   {
                    this.programmi.splice(i, 1, programmi[j]);
                }
                else if(j>=0) {
                    this.programmi.push(programmi[j]);
                }                
            },
            nuovoProgramma() {
                this.programma={ id:"0"};
                bus.$emit('dialog-show-tes', this.programma);
            },    
            localData(v) {
                return gLocalData(v);
            },
            serverData(v) {
                return gServerData(v);
            },
            apiData(v) {
                return gApiData(v);
            },
            async ricalcola() {
                this.loadingProgrammi = true;
                let param = [this.$store.state.congregazioneCorrente.partitionReference, this.dataIniziale];
                this.programmi = await callService("api/programma/listaTestimonianza", param);
                this.loadingProgrammi = false;
            },
        },        
        async created() {
            this.dataIniziale = this.apiData(new Date());  //oggi
            await this.ricalcola();
            this.SUPERADMIN_USERNAME = SUPERADMIN_USERNAME;
            this.isAdmin =this.$store.state.iscritto.username==SUPERADMIN_USERNAME || this.$store.state.iscritto.isAdmin;
            bus.$on('dialog-update-tes', this.onUpdateProgramma)
        },
        beforeDestroy() {
            bus.$off('dialog-update-tes', this.onUpdateProgramma)
        }
        
    }</script>
