<template>
    <v-container>
        <template>
            <v-data-table :loading="loadingIscritti"
                            :headers="headersIscritti"
                            :items="iscritti"
                            :items-per-page="8"
                            loading-text="Caricamento dati..."
                            no-data-text="Nessuno iscritto presente"
                            no-results-text="Nessun iscritto corrispondente ai criteri di ricerca"
                            :search="search"
                            :footer-props="{
                    'items-per-page-text':'Pagina da',
                    'items-per-page-all-text': 'Tutti',
                    'items-per-page-options':[5,8,10,20,-1]
                    }"
                            class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title><b>Iscritti</b></v-toolbar-title>
                        <v-col cols="5">
                            <v-text-field class="mt-6 ml-4"
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Ricerca un iscritto..."
                                            single-line
                                            outlined
                                            clearable
                                            dense>
                            </v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-tooltip v-if="isAdmin" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon x-large
                                        color="info"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="nuovoIscritto">
                                    <v-icon>mdi-view-grid-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Nuovo Iscritto</span>
                        </v-tooltip>
                    </v-toolbar>
                </template>
                <template v-slot:item.ruolo="{ item }">
                    <v-chip v-if="item.isAdmin" class="mr-2" x-small dark color="orange">ADMIN</v-chip>
                    <br />
                    <v-chip v-if="item.username==SUPERADMIN_USERNAME" class="mr-2" x-small dark color="green">SUPER ADMIN</v-chip>
                </template>
                <template v-slot:item.sesso="{ item }">
                    <v-chip v-if="item.isUomo" class="mr-2" x-small dark color="blue">M</v-chip>
                    <v-chip v-if="item.isDonna" class="mr-2" x-small dark color="pink">F</v-chip>
                </template>
                <template v-slot:item.dettaglio="{ item }">
                    <div>
                        <v-icon @click="clickIscritto(item)">mdi-magnify</v-icon>
                    </div>
                </template>
            </v-data-table>           


            <iscritto-dialog v-model="dialogIscritto" editIscritto="iscritto" />

        </template>
    </v-container>
</template>

<script>

    import {callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack, SUPERADMIN_USERNAME} from '@/modules/utilities.js'
    import IscrittoDialog from '@/views/components/IscrittoDialog.vue'
    import { bus } from '@/main.js'

    export default {
        components:{
            IscrittoDialog
        },
        data: () => ({            
            headersIscritti: [
                {text: '', align: 'start', value: 'ruolo'},
                {text: 'Nome', align: 'start', value: 'nome'},
                {text: 'Cognone', align: 'start', value: 'cognome'},
                { text: 'Sesso', value: 'sesso', sortable: false },
                { text: 'Telefono', value: 'telefono', sortable: false },
                { text: 'Mail', value: 'mail', sortable: false },
                { text: '', value: 'dettaglio', sortable: false },
            ],
            iscritti: [],
            dialogIscritto: false,
            iscritto: {},
            loadingIscritti:false,
            SUPERADMIN_USERNAME: '',
            isAdmin: false,
            search: ''  
        }),
        computed: {
        },
        methods: {
            async clickIscritto(iscritto) {    
                this.iscritto=JSON.parse(JSON.stringify(iscritto));
                let param=[this.$store.state.idCongregazione,this.iscritto.id];
                bus.$emit('dialog-show-iscritto', this.iscritto);
            },
            async onUpdateIscritto(iscritto) {
                const i = this.iscritti.findIndex(o => o.id == iscritto.id);
                if (i>=0)   {
                    this.iscritti.splice(i, 1, iscritto);
                }
                else {
                    this.iscritti.push(iscritto);
                }
            },
            nuovoIscritto() {
                this.iscritto={ id:"0", isUomo: true };
                bus.$emit('dialog-show-iscritto', this.iscritto);
            }
        },
        async created() {
            this.loadingIscritti = true;
            let param = [this.$store.state.congregazioneCorrente.partitionReference];
            this.iscritti = await callService("api/iscritto/lista", param);
            this.loadingIscritti = false;
            this.SUPERADMIN_USERNAME = SUPERADMIN_USERNAME;
            this.isAdmin =this.$store.state.iscritto.username==SUPERADMIN_USERNAME || this.$store.state.iscritto.isAdmin;
            bus.$on('dialog-update-iscritto', this.onUpdateIscritto)
        },
        beforeDestroy() {
            bus.$off('dialog-update-iscritto', this.onUpdateIscritto)
        }
        
    }</script>
