<template>
    <v-dialog v-model="dialog" persistent max-width="1000px">
        <v-card class="elevation-24 rounded-lg">
            <v-card-title></v-card-title>
            <v-card-text>
                <v-form :readonly="!modificheAbilitate" v-model="valid" ref="formIscritto">
                    <v-row>
                        <v-col cols="3">
                            <v-text-field v-model="iscritto.nome"
                                          outlined
                                          autofocus
                                          label="Nome"
                                          :rules="[v=> !!v || 'campo obbligatorio']"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field v-model="iscritto.cognome"
                                          outlined
                                          label="Cognome"
                                          :rules="[v=> !!v || 'campo obbligatorio']"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-radio-group dense class="mt-1"
                                           row
                                           v-model="sesso"
                                           @change="changeSesso">
                                <v-radio label="uomo"
                                         value="0">
                                </v-radio>
                                <v-radio label="donna"
                                         value="1">
                                </v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-text-field v-model="iscritto.username"
                                          outlined
                                          autocomplete="new-username"
                                          label="Username"
                                          @keyup="checkUsername"
                                          :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida', v => message!='0' || 'indirizzo email già presente nel sistema']"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field v-model="iscritto.password"
                                          outlined
                                          autocomplete="new-password"
                                          type="password"
                                          label="Password"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-checkbox color="red" v-model="iscritto.isAdmin"
                                        outlined
                                        label="admin"
                                        dense>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-text-field v-model="iscritto.telefono"
                                          outlined
                                          label="Telefono"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field v-model="iscritto.mail"
                                          outlined
                                          label="Indirizzo email"
                                          :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-checkbox color="red" v-model="iscritto.isIndisponibile"
                                        outlined
                                        label="indisponibilità temporanea"
                                        dense>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-tabs>
                            <v-tab>Vita Cristiana</v-tab>
                            <v-tab>Studenti Efficaci</v-tab>
                            <v-tab>Incarichi</v-tab>
                            <v-tab-item>
                                <v-row>
                                    <v-col>
                                        <div v-for="dettaglio in dettagli.listaVita.slice(0,4)">
                                            <v-checkbox dense v-model="dettaglio.isSvolta"
                                                        :label="dettaglio.tipoParte.descrizione" />
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div v-for="dettaglio in dettagli.listaVita.slice(4,8)">
                                            <v-checkbox dense v-model="dettaglio.isSvolta"
                                                        :label="dettaglio.tipoParte.descrizione" />
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div v-for="dettaglio in dettagli.listaVita.slice(8,)">
                                            <v-checkbox dense v-model="dettaglio.isSvolta"
                                                        :label="dettaglio.tipoParte.descrizione" />
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col>
                                        <div v-for="dettaglio in dettagli.listaEff.slice(0,4)">
                                            <v-checkbox dense v-model="dettaglio.isSvolta"
                                                        :label="dettaglio.tipoParte.descrizione" />
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div v-for="dettaglio in dettagli.listaEff.slice(4,8)">
                                            <v-checkbox dense v-model="dettaglio.isSvolta"
                                                        :label="dettaglio.tipoParte.descrizione" />
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div v-for="dettaglio in dettagli.listaEff.slice(8,)">
                                            <v-checkbox dense v-model="dettaglio.isSvolta"
                                                        :label="dettaglio.tipoParte.descrizione" />
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row>
                                    <v-col>
                                        <div v-for="dettaglio in dettagli.listaInc.slice(0,4)">
                                            <v-checkbox dense v-model="dettaglio.isSvolta"
                                                        :label="dettaglio.tipoParte.descrizione" />
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div v-for="dettaglio in dettagli.listaInc.slice(4,8)">
                                            <v-checkbox dense v-model="dettaglio.isSvolta"
                                                        :label="dettaglio.tipoParte.descrizione" />
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div v-for="dettaglio in dettagli.listaInc.slice(8,12)">
                                            <v-checkbox dense v-model="dettaglio.isSvolta"
                                                        :label="dettaglio.tipoParte.descrizione" />
                                        </div>
                                    </v-col>
                                    <v-col cols="4">
                                        <br />
                                        <v-text-field @keyup="uppercaseTagList" v-model="iscritto.tagListConduttori"
                                                      outlined
                                                      label="Lista Tag Conduttori"
                                                      dense>
                                        </v-text-field>
                                        <v-text-field @keyup="uppercaseTagList" v-model="iscritto.tagList"
                                                      outlined
                                                      label="Lista Tag Testimonianza Pub."
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="modificheAbilitate" :disabled="!valid" color="error" @click="save">Salva</v-btn>
                <v-btn color="warning" :loading="loading" @click.stop="dialog = false">Chiudi</v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackInfo"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Credenziali copiate in memoria.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-dialog>
</template>

<script>
    import {STD_PASSWORD, SUPERADMIN_USERNAME, callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack} from '@/modules/utilities.js'

    import { bus } from '@/main.js'

    export default {
        data() {
            return {
                dialog: false,
                iscritto: {},
                sesso: '0',
                valid: false,   
                snackInfo: false,
                snackSuccess: false,
                snackError: false,
                snackCancel: false,
                snackRemote: false,
                SUPERADMIN_USERNAME: '',
                loading:false,
                dettagli: [],
                message:''
            }
        },
        methods: {
            async save() {
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.iscritto.username,
                    token: this.$store.state.iscritto.token,
                    idCongregazione: this.$store.state.congregazioneCorrente.partitionReference,
                    iscritto: this.iscritto,
                    dettagli: this.dettagli
                };
                let iscritto = await callPost('api/iscritto/save', paramJson);

                if(iscritto) this.snackSuccess =true;
                else this.snackError = true;

                if(iscritto) {

                    bus.$emit('dialog-update-iscritto', iscritto);
                   
                    //ricarico

                    let param=[this.$store.state.congregazione.id,iscritto.id];
                    bus.$emit('dialog-show-iscritto',iscritto);
                }              
            },                
            filterFunction(item, queryText) {
                return (                    
                    (item.diId+"").toLowerCase().includes(queryText.toLowerCase()) ||  
                    item.tema.toLowerCase().includes(queryText.toLowerCase())
                  );
            },           
            clickElimina(item) {
                if(item.tipoModifica==0) {
                    item.tipoModifica=2;
                }
                else if(item.tipoModifica==1) {
                    this.discorsi.splice(this.discorsi.findIndex(d=>d.diId==item.diId));
                }
                else if(item.tipoModifica==2) {
                    item.tipoModifica=0;
                }
                if (this.page > Math.ceil(this.discorsi.length / 5)) {
                    this.page = Math.ceil(this.discorsi.length / 5);
                }
            },
            changeSesso() {
                this.iscritto.isUomo = (this.sesso=='0');
                this.iscritto.isDonna = (this.sesso=='1');
            },
            async checkUsername() {
                
                let param = [this.$store.state.congregazioneCorrente.partitionReference, this.iscritto.id, this.iscritto.username];
                let risposta = await callService("api/iscritto/checkUsername", param);
                this.message = risposta ? "" : "0";
                this.$refs.formIscritto.validate();
            },
             uppercaseTagList() {
                this.iscritto.tagList = this.iscritto.tagList.toUpperCase();
            }
        },
        computed: {     
            modificheAbilitate() {
                if(this.iscritto.id == "0") return true;
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                else if(this.$store.state.iscritto.isAdmin) b=true;

                return b;
            },
            isAdmin() {
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                else if(this.$store.state.iscritto.isAdmin) b = true;

                return b;
            },
            isSuperAdmin() {
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                return b;
            }
        },
        async created() {
            this.SUPERADMIN_USERNAME = SUPERADMIN_USERNAME;
            var vm = this;
            bus.$on('dialog-show-iscritto', async function (iscritto) {
                vm.dialog = true;
                vm.iscritto = iscritto;
                vm.sesso = iscritto.isUomo ? '0':'1';

                vm.loading = true;
                
                let param = [vm.$store.state.congregazioneCorrente.partitionReference, iscritto.id];
                vm.dettagli = await callService("api/iscritto/dettagli", param);
                vm.loading = false;
    
                vm.$nextTick(() => {
                    if(!vm.$refs.formIscritto) return;
                    vm.$refs.formIscritto.resetValidation();
                });
            });            
        },
        beforeDestroy() {
            bus.$off('dialog-show-iscritto');
        }
    }
</script>