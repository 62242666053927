<template>
    <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card class="elevation-24 rounded-lg">
            <v-card-title></v-card-title>
            <v-card-text>
                <v-form :readonly="!modificheAbilitate" v-model="valid" ref="formIscritto">
                    <v-row>
                        <v-col cols="10">
                            <v-text-field v-model="iscritto.nome"
                                          outlined
                                          autofocus
                                          label="Nome Gruppo"
                                          :rules="[v=> !!v || 'campo obbligatorio']"
                                          dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div v-for="dettaglio in dettagli.listaInc.slice(2,3)">
                                <v-checkbox dense v-model="dettaglio.isSvolta"
                                            :label="dettaglio.tipoParte.descrizione" />
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>                       
                        <v-col cols="10">
                            <v-text-field @keyup="uppercaseTagList" v-model="iscritto.tagList"
                                          outlined
                                          label="Lista Tag Testimonianza Pub."
                                          dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="modificheAbilitate" :disabled="!valid" color="error" @click="save">Salva</v-btn>
                <v-btn color="warning" :loading="loading" @click.stop="dialog = false">Chiudi</v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackInfo"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Credenziali copiate in memoria.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-dialog>
</template>

<script>
    import {STD_PASSWORD, SUPERADMIN_USERNAME, callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack} from '@/modules/utilities.js'

    import { bus } from '@/main.js'

    export default {
        data() {
            return {
                dialog: false,
                iscritto: {},
                sesso: '0',
                valid: false,   
                snackInfo: false,
                snackSuccess: false,
                snackError: false,
                snackCancel: false,
                snackRemote: false,
                SUPERADMIN_USERNAME: '',
                loading:false,
                dettagli: [],
                message:''
            }
        },
        methods: {
            async save() {
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.iscritto.username,
                    token: this.$store.state.iscritto.token,
                    idCongregazione: this.$store.state.congregazioneCorrente.partitionReference,
                    iscritto: this.iscritto,
                    dettagli: this.dettagli,
                    isGruppo: true
                };
                let iscritto = await callPost('api/iscritto/save', paramJson);

                if(iscritto) this.snackSuccess =true;
                else this.snackError = true;

                if(iscritto) {

                    bus.$emit('dialog-update-gruppo', iscritto);
                   
                    //ricarico

                    let param=[this.$store.state.congregazione.id,iscritto.id];
                    bus.$emit('dialog-show-gruppo',iscritto);
                }              
            },                
            filterFunction(item, queryText) {
                return (                    
                    (item.diId+"").toLowerCase().includes(queryText.toLowerCase()) ||  
                    item.tema.toLowerCase().includes(queryText.toLowerCase())
                  );
            },           
            clickElimina(item) {
                if(item.tipoModifica==0) {
                    item.tipoModifica=2;
                }
                else if(item.tipoModifica==1) {
                    this.discorsi.splice(this.discorsi.findIndex(d=>d.diId==item.diId));
                }
                else if(item.tipoModifica==2) {
                    item.tipoModifica=0;
                }
                if (this.page > Math.ceil(this.discorsi.length / 5)) {
                    this.page = Math.ceil(this.discorsi.length / 5);
                }
            },
            uppercaseTagList() {
                this.iscritto.tagList = this.iscritto.tagList.toUpperCase();
            }
        },
        computed: {     
            modificheAbilitate() {
                if(this.iscritto.id == "0") return true;
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                else if(this.$store.state.iscritto.isAdmin) b=true;

                return b;
            },
            isAdmin() {
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                else if(this.$store.state.iscritto.isAdmin) b = true;

                return b;
            },
            isSuperAdmin() {
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                return b;
            }
        },
        async created() {
            this.SUPERADMIN_USERNAME = SUPERADMIN_USERNAME;

            var vm = this;
            bus.$on('dialog-show-gruppo', async function (iscritto) {
                vm.dialog = true;
                vm.iscritto = iscritto;
                vm.sesso = iscritto.isUomo ? '0':'1';

                vm.loading = true;
                
                let param = [vm.$store.state.congregazioneCorrente.partitionReference, iscritto.id];
                vm.dettagli = await callService("api/iscritto/dettagli", param);
                vm.loading = false;
    
                vm.$nextTick(() => {
                    if(!vm.$refs.formIscritto) return;
                    vm.$refs.formIscritto.resetValidation();
                });
            });            
        },
        beforeDestroy() {
            bus.$off('dialog-show-gruppo');
        }
    }
</script>