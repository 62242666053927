import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        logged: false, //accesso effettuato

        partition: '', //partizione corrente

        iscritto: null, //iscritto connesso

        congregazione: null, //congregazione connesso
        
        congregazioneCorrente: null, //congregazione corrente        
    },

    mutations: {
        logout(state) {          
            state.logged = false;
        },
        login(state) {           
            state.logged = true;
        }
    },

    actions: { 
        logout(context) {           
            context.commit('logout')           
        },
        login(context) {
            context.commit('login')
        }
    }
});