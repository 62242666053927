import Store from '../store/store'
import Router from '../router';


//enumerable per gli snack

export const Snack = {
    success: 0,
    error: 1,
    cancel: 2,
    nothing: 4,
    remote: 5
}

export const STD_PASSWORD = '25201914';
export const SUPERADMIN_USERNAME = "luigi.degiacomo@gmail.com";

export const callService = async function (service, param, noStatic) {
    
    let serviceStatic = [service];
    let paramStatic = noStatic ? [] : [Store.state.partition, Store.state.iscritto.username, Store.state.iscritto.token];
    let paramList = (serviceStatic.concat(paramStatic).concat(param)).join("/");
    const response = await fetch(paramList);

    //errore di login/accesso

    if (response.status >= 400 && response.status <= 499) {
        logout();
        return null;
    }

    //errore applicativo

    if (response.status >= 500) {
        return false;
    };

    const data = await response.json();
    return data;
};

export const callPost = async function (service, paramJson, byPassError) {

    const response = await fetch(service, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(paramJson)
    });

    //errore di login/accesso

    if (response.status >= 400 && response.status<=499) {
        if (byPassError) {
            return null;
        }
        else {
            logout();
            return;
        }
    };

    //errore applicativo

    if (response.status >= 500) {
        if (byPassError) {
            return null;
        }
        else {            
            return false;
        }
    };
    const data = await response.json();
    return data;
};

export const euro = function (importo) {
    return (Number(importo)).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
};

export const gLocalData = function (stringDate) {
    if (!stringDate) return null;
    var d = new Date(stringDate);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [day, month, year].join('/');
};

export const gLocalDataTime = function (stringDate) {
    if (!stringDate) return null;
    var d = new Date(stringDate);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear().toString();
    let hour = d.getHours().toString();
    let min = d.getMinutes().toString();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    if (hour.length < 2) {
        hour = '0' + hour;
    }
    if (min.length < 2) {
        min = '0' + min;
    }
    return [day, month, year].join('/')+" "+hour+":"+min;
};

export const gDataCalendar = function (stringDate) {
    if (!stringDate) return null;
    var d = new Date(stringDate);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
};

export const gCheckData = function (d) {
    var tok = d.split("/");
    var dIso = new Date([tok[2], tok[1], tok[0]].join("-"));
    return !isNaN(dIso);
};

export const gApiData = function (d) {
    var data = d.toISOString().substring(0, 10);
    return data;
};

export const gServerData = function (stringDate) {
    //da gg/mm/aaaa a aaaa-mm-gg//

    if (!stringDate) return null;
    var tok = stringDate.split("/");
    return [tok[2], tok[1], tok[0]].join('/');
};

export const logout = function () {
    Store.dispatch('logout');
    Store.dispatch('setUtente', null);
    Router.replace({ name: "login" });
};
