<template>
    <v-dialog v-model="dialog" persistent max-width="1200px">
        <v-card class="elevation-24 rounded-lg">
            <v-card-title></v-card-title>
            <v-card-text>
                <v-form v-if="dettagli.materiale && dettagli.parti" :readonly="!modificheAbilitate" v-model="valid" ref="formVita">
                    <v-row>
                        <v-col cols="2">
                            <v-text-field v-model="programma.data"
                                          hide-details
                                          outlined
                                          autofocus
                                          @change="updateJW"
                                          type="date"
                                          label="Data"
                                          :rules="[v=> !!v || 'campo obbligatorio']"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field v-model="dettagli.materiale[10].descrizione"
                                          outlined
                                          hide-details
                                          label="Lettura biblica settimanale"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="3">
                            <v-autocomplete v-model="dettagli.parti[0].parte.iscritto.id"
                                            :items="dettagli.parti[0].iscrittiEstesi"
                                            chips
                                            no-data-text="Nessun iscritto trovato"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            :label="dettagli.parti[0].parte.tipoParte.descrizione"
                                            :placeholder="dettagli.parti[0].parte.tipoParte.descrizione"
                                            item-text="iscritto.nominativo"
                                            item-value="iscritto.id">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ data.item.iscritto.nominativo }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete v-model="dettagli.parti[18].parte.iscritto.id"
                                            :items="dettagli.parti[18].iscrittiEstesi"
                                            chips
                                            no-data-text="Nessun iscritto trovato"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            :label="dettagli.parti[18].parte.tipoParte.descrizione"
                                            :placeholder="dettagli.parti[18].parte.tipoParte.descrizione"
                                            item-text="iscritto.nominativo"
                                            item-value="iscritto.id">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ data.item.iscritto.nominativo }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-text-field v-model="dettagli.materiale[0].descrizione"
                                          outlined
                                          hide-details
                                          label="Cantico"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="3">
                            <v-autocomplete v-model="dettagli.parti[1].parte.iscritto.id"
                                            :items="dettagli.parti[1].iscrittiEstesi"
                                            chips
                                            no-data-text="Nessun iscritto trovato"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            :label="dettagli.parti[1].parte.tipoParte.descrizione"
                                            :placeholder="dettagli.parti[1].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                            item-value="iscritto.id">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ data.item.iscritto.nominativo }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-tabs v-model="tab">
                        <v-tab>Tesori della Parola di Dio</v-tab>
                        <v-tab>Efficaci nel Ministero</v-tab>
                        <v-tab>Vita Cristiana</v-tab>
                        <v-tab>Html JW</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card class="mt-4 mb-8 mx-4">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="dettagli.materiale[1].descrizione"
                                                          outlined
                                                          hide-details
                                                          label="1a parte Tesori"
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="3">
                                            <v-autocomplete v-model="dettagli.parti[2].parte.iscritto.id"
                                                            :items="dettagli.parti[2].iscrittiEstesi"
                                                            chips
                                                            no-data-text="Nessun iscritto trovato"
                                                            dense
                                                            outlined
                                                            clearable
                                                            hide-details
                                                            :label="dettagli.parti[2].parte.tipoParte.descrizione"
                                                            :placeholder="dettagli.parti[2].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                            item-value="iscritto.id">
                                                <template v-slot:selection="data">
                                                    <span>
                                                        {{ data.item.iscritto.nominativo }}
                                                    </span>
                                                </template>
                                                <template v-slot:item="data">
                                                    <template>
                                                        <v-list-item-content>
                                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field value="Gemme Spirituali"
                                                          outlined
                                                          hide-details
                                                          readonly
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="3">
                                            <v-autocomplete v-model="dettagli.parti[3].parte.iscritto.id"
                                                            :items="dettagli.parti[3].iscrittiEstesi"
                                                            chips
                                                            no-data-text="Nessun iscritto trovato"
                                                            dense
                                                            outlined
                                                            clearable
                                                            hide-details
                                                            :label="dettagli.parti[3].parte.tipoParte.descrizione"
                                                            :placeholder="dettagli.parti[3].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                            item-value="iscritto.id">
                                                <template v-slot:selection="data">
                                                    <span>
                                                        {{ data.item.iscritto.nominativo }}
                                                    </span>
                                                </template>
                                                <template v-slot:item="data">
                                                    <template>
                                                        <v-list-item-content>
                                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field value="Lettura Biblica"
                                                          outlined
                                                          hide-details
                                                          readonly
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="3">
                                            <v-autocomplete v-model="dettagli.parti[4].parte.iscritto.id"
                                                            :items="dettagli.parti[4].iscrittiEstesi"
                                                            chips
                                                            no-data-text="Nessun iscritto trovato"
                                                            dense
                                                            outlined
                                                            clearable
                                                            hide-details
                                                            :prepend-icon="viewAss0 ? 'mdi-newspaper-variant-outline':'mdi-magnify'"
                                                            @click:prepend="printPdf(0)"
                                                            :label="dettagli.parti[4].parte.tipoParte.descrizione"
                                                            :placeholder="dettagli.parti[4].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                            item-value="iscritto.id">
                                                <template v-slot:selection="data">
                                                    <span>
                                                        {{ data.item.iscritto.nominativo }}
                                                    </span>
                                                </template>
                                                <template v-slot:item="data">
                                                    <template>
                                                        <v-list-item-content>
                                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                            <v-autocomplete class="mt-4" v-model="dettagli.parti[27].parte.iscritto.id"
                                                            :items="dettagli.parti[27].iscrittiEstesi"
                                                            chips
                                                            no-data-text="Nessun iscritto trovato"
                                                            dense
                                                            outlined
                                                            clearable
                                                            hide-details
                                                            :prepend-icon="viewAss5 ? 'mdi-newspaper-variant-outline':'mdi-magnify'"
                                                            @click:prepend="printPdf(5)"
                                                            :label="dettagli.parti[27].parte.tipoParte.descrizione"
                                                            :placeholder="dettagli.parti[27].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                            item-value="iscritto.id">
                                                <template v-slot:selection="data">
                                                    <span>
                                                        {{ data.item.iscritto.nominativo }}
                                                    </span>
                                                </template>
                                                <template v-slot:item="data">
                                                    <template>
                                                        <v-list-item-content>
                                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>

                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card class="mt-4 mb-8 mx-4">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field v-model="dettagli.materiale[2].descrizione"
                                                          outlined
                                                          hide-details                                                          
                                                          label="1a parte Efficaci nel Ministero"
                                                          dense>
                                            </v-text-field>
                                        </v-col>                                        
                                        <v-col cols="8">
                                            <v-row>
                                                <v-col cols="2">
                                                    <v-select dense
                                                              outlined
                                                              hide-details
                                                              label="Tipo"
                                                              item-text="id"
                                                              item-value="id"
                                                              @change="changeTipo(dettagli.parti[5].parte.tipoParte.tag,5)"
                                                              v-model="dettagli.parti[5].parte.tipoParte.tag"
                                                              :items="[
                                                                        {id:'VD',desc:'Video'},
                                                                        {id:'PC',desc:'Primo Contatto'},
                                                                        {id:'VU',desc:'Visita Ulteriore'},
                                                                        {id:'SB',desc:'Studio Biblico'},
                                                                        {id:'DS',desc:'Discorso'},
                                                                    ]">
                                                        <template v-slot:item="{item}">
                                                            <span>
                                                                {{ item.desc }}
                                                            </span>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="5">
                                                    <v-autocomplete v-model="dettagli.parti[5].parte.iscritto.id"
                                                                    :items="dettagli.parti[5].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :prepend-icon="viewAss1 ? 'mdi-newspaper-variant-outline':'mdi-magnify'"
                                                                    @click:prepend="printPdf(1)"
                                                                    :label="dettagli.parti[5].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[5].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                    <v-autocomplete class="mt-4" v-model="dettagli.parti[19].parte.iscritto.id"
                                                                    :items="dettagli.parti[19].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :prepend-icon="viewAss6 ? 'mdi-newspaper-variant-outline':'mdi-magnify'"
                                                                    @click:prepend="printPdf(6)"
                                                                    :label="dettagli.parti[19].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[19].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="5">
                                                    <v-autocomplete v-if="!['VD','DS'].includes(dettagli.parti[5].parte.tipoParte.tag)" v-model="dettagli.parti[6].parte.iscritto.id"
                                                                    :items="dettagli.parti[6].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :label="dettagli.parti[6].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[6].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                    <v-autocomplete class="mt-4" v-if="!['VD','DS'].includes(dettagli.parti[19].parte.tipoParte.tag)" v-model="dettagli.parti[20].parte.iscritto.id"
                                                                    :items="dettagli.parti[20].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :label="dettagli.parti[20].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[20].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field v-model="dettagli.materiale[3].descrizione"
                                                          outlined
                                                          hide-details
                                                          label="2a parte Efficaci nel Ministero"
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-row>
                                                <v-col cols="2">
                                                    <v-select dense
                                                              outlined
                                                              hide-details
                                                              label="Tipo"
                                                              item-text="id"
                                                              item-value="id"
                                                              @change="changeTipo(dettagli.parti[7].parte.tipoParte.tag,7)"
                                                              v-model="dettagli.parti[7].parte.tipoParte.tag"
                                                              :items="[
                                                                        {id:'PC',desc:'Primo Contatto'},
                                                                        {id:'VU',desc:'Visita Ulteriore'},
                                                                        {id:'SB',desc:'Studio Biblico'},
                                                                        {id:'DS',desc:'Discorso'}
                                                                    ]">
                                                        <template v-slot:item="{item}">
                                                            <span>
                                                                {{ item.desc }}
                                                            </span>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="5">
                                                    <v-autocomplete v-model="dettagli.parti[7].parte.iscritto.id"
                                                                    :items="dettagli.parti[7].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :prepend-icon="viewAss2 ? 'mdi-newspaper-variant-outline':'mdi-magnify'"
                                                                    @click:prepend="printPdf(2)"
                                                                    :label="dettagli.parti[7].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[7].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                    <v-autocomplete class="mt-4" v-model="dettagli.parti[21].parte.iscritto.id"
                                                                    :items="dettagli.parti[21].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :prepend-icon="viewAss7 ? 'mdi-newspaper-variant-outline':'mdi-magnify'"
                                                                    @click:prepend="printPdf(7)"
                                                                    :label="dettagli.parti[21].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[21].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="5">
                                                    <v-autocomplete v-if="!['VD','DS'].includes(dettagli.parti[7].parte.tipoParte.tag)" v-model="dettagli.parti[8].parte.iscritto.id"
                                                                    :items="dettagli.parti[8].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :label="dettagli.parti[8].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[8].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                    <v-autocomplete class="mt-4" v-if="!['VD','DS'].includes(dettagli.parti[21].parte.tipoParte.tag)" v-model="dettagli.parti[22].parte.iscritto.id"
                                                                    :items="dettagli.parti[20].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :label="dettagli.parti[22].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[22].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field v-model="dettagli.materiale[4].descrizione"
                                                          outlined
                                                          hide-details
                                                          label="3a parte Efficaci nel Ministero"
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-row>
                                                <v-col cols="2">
                                                    <v-select dense
                                                              outlined
                                                              hide-details
                                                              label="Tipo"
                                                              item-text="id"
                                                              item-value="id"
                                                              @change="changeTipo(dettagli.parti[9].parte.tipoParte.tag,9)"
                                                              v-model="dettagli.parti[9].parte.tipoParte.tag"
                                                              :items="[
                                                                        {id:'PC',desc:'Primo Contatto'},
                                                                        {id:'VU',desc:'Visita Ulteriore'},
                                                                        {id:'SB',desc:'Studio Biblico'},
                                                                        {id:'DS',desc:'Discorso'}
                                                                    ]">
                                                        <template v-slot:item="{item}">
                                                            <span>
                                                                {{ item.desc }}
                                                            </span>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="5">
                                                    <v-autocomplete v-model="dettagli.parti[9].parte.iscritto.id"
                                                                    :items="dettagli.parti[9].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :prepend-icon="viewAss3 ? 'mdi-newspaper-variant-outline':'mdi-magnify'"
                                                                    @click:prepend="printPdf(3)"
                                                                    :label="dettagli.parti[9].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[9].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                    <v-autocomplete class="mt-4" v-model="dettagli.parti[23].parte.iscritto.id"
                                                                    :items="dettagli.parti[23].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :prepend-icon="viewAss8 ? 'mdi-newspaper-variant-outline':'mdi-magnify'"
                                                                    @click:prepend="printPdf(8)"
                                                                    :label="dettagli.parti[23].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[23].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="5">
                                                    <v-autocomplete v-if="!['VD','DS'].includes(dettagli.parti[9].parte.tipoParte.tag)" v-model="dettagli.parti[10].parte.iscritto.id"
                                                                    :items="dettagli.parti[10].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :label="dettagli.parti[10].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[10].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                    <v-autocomplete class="mt-4" v-if="!['VD','DS'].includes(dettagli.parti[23].parte.tipoParte.tag)" v-model="dettagli.parti[24].parte.iscritto.id"
                                                                    :items="dettagli.parti[24].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :label="dettagli.parti[24].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[24].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field v-model="dettagli.materiale[5].descrizione"
                                                          outlined
                                                          hide-details
                                                          label="4a parte Efficaci nel Ministero"
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-row>
                                                <v-col cols="2">
                                                    <v-select dense
                                                              outlined
                                                              hide-details
                                                              label="Tipo"
                                                              item-text="id"
                                                              item-value="id"
                                                              @change="changeTipo(dettagli.parti[11].parte.tipoParte.tag,11)"
                                                              v-model="dettagli.parti[11].parte.tipoParte.tag"
                                                              :items="[
                                                                        {id:'PC',desc:'Primo Contatto'},
                                                                        {id:'VU',desc:'Visita Ulteriore'},
                                                                        {id:'SB',desc:'Studio Biblico'},
                                                                        {id:'DS',desc:'Discorso'}
                                                                    ]">
                                                        <template v-slot:item="{item}">
                                                            <span>
                                                                {{ item.desc }}
                                                            </span>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="5">
                                                    <v-autocomplete v-model="dettagli.parti[11].parte.iscritto.id"
                                                                    :items="dettagli.parti[11].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :prepend-icon="viewAss4 ? 'mdi-newspaper-variant-outline':'mdi-magnify'"
                                                                    @click:prepend="printPdf(4)"
                                                                    :label="dettagli.parti[11].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[11].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                    <v-autocomplete class="mt-4" v-model="dettagli.parti[25].parte.iscritto.id"
                                                                    :items="dettagli.parti[25].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :prepend-icon="viewAss9 ? 'mdi-newspaper-variant-outline':'mdi-magnify'"
                                                                    @click:prepend="printPdf(9)"
                                                                    :label="dettagli.parti[25].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[25].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>

                                                </v-col>
                                                <v-col cols="5">
                                                    <v-autocomplete v-if="!['VD','DS'].includes(dettagli.parti[11].parte.tipoParte.tag)" v-model="dettagli.parti[12].parte.iscritto.id"
                                                                    :items="dettagli.parti[12].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :label="dettagli.parti[12].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[12].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                    <v-autocomplete class="mt-4" v-if="!['VD','DS'].includes(dettagli.parti[25].parte.tipoParte.tag)" v-model="dettagli.parti[26].parte.iscritto.id"
                                                                    :items="dettagli.parti[26].iscrittiEstesi"
                                                                    chips
                                                                    no-data-text="Nessun iscritto trovato"
                                                                    dense
                                                                    outlined
                                                                    clearable
                                                                    hide-details
                                                                    :label="dettagli.parti[26].parte.tipoParte.descrizione"
                                                                    :placeholder="dettagli.parti[26].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                                    item-value="iscritto.id">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ data.item.iscritto.nominativo }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                                    <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card class="mt-4 mb-8 mx-4">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="2">
                                            <v-text-field v-model="dettagli.materiale[6].descrizione"
                                                          outlined
                                                          hide-details
                                                          label="Cantico"
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="dettagli.materiale[7].descrizione"
                                                          outlined
                                                          hide-details
                                                          label="1a parte Vita Cristiana"
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="3">
                                            <v-autocomplete v-model="dettagli.parti[13].parte.iscritto.id"
                                                            :items="dettagli.parti[13].iscrittiEstesi"
                                                            chips
                                                            no-data-text="Nessun iscritto trovato"
                                                            dense
                                                            outlined
                                                            clearable
                                                            hide-details
                                                            :label="dettagli.parti[13].parte.tipoParte.descrizione"
                                                            :placeholder="dettagli.parti[13].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                            item-value="iscritto.id">
                                                <template v-slot:selection="data">
                                                    <span>
                                                        {{ data.item.iscritto.nominativo }}
                                                    </span>
                                                </template>
                                                <template v-slot:item="data">
                                                    <template>
                                                        <v-list-item-content>
                                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="dettagli.materiale[8].descrizione"
                                                          outlined
                                                          hide-details
                                                          label="2a parte Vita Cristiana"
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="3">
                                            <v-autocomplete v-model="dettagli.parti[14].parte.iscritto.id"
                                                            :items="dettagli.parti[14].iscrittiEstesi"
                                                            chips
                                                            no-data-text="Nessun iscritto trovato"
                                                            dense
                                                            outlined
                                                            clearable
                                                            hide-details
                                                            :label="dettagli.parti[14].parte.tipoParte.descrizione"
                                                            :placeholder="dettagli.parti[14].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                            item-value="iscritto.id">
                                                <template v-slot:selection="data">
                                                    <span>
                                                        {{ data.item.iscritto.nominativo }}
                                                    </span>
                                                </template>
                                                <template v-slot:item="data">
                                                    <template>
                                                        <v-list-item-content>
                                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field value="Studio Biblico di Congregazione"
                                                          outlined
                                                          hide-details
                                                          readonly
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-autocomplete v-model="dettagli.parti[15].parte.iscritto.id"
                                                            :items="dettagli.parti[15].iscrittiEstesi"
                                                            chips
                                                            no-data-text="Nessun iscritto trovato"
                                                            dense
                                                            outlined
                                                            clearable
                                                            hide-details
                                                            :label="dettagli.parti[15].parte.tipoParte.descrizione"
                                                            :placeholder="dettagli.parti[15].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                            item-value="iscritto.id">
                                                <template v-slot:selection="data">
                                                    <span>
                                                        {{ data.item.iscritto.nominativo }}
                                                    </span>
                                                </template>
                                                <template v-slot:item="data">
                                                    <template>
                                                        <v-list-item-content>
                                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-autocomplete v-model="dettagli.parti[16].parte.iscritto.id"
                                                            :items="dettagli.parti[16].iscrittiEstesi"
                                                            chips
                                                            no-data-text="Nessun iscritto trovato"
                                                            dense
                                                            outlined
                                                            clearable
                                                            hide-details
                                                            :label="dettagli.parti[16].parte.tipoParte.descrizione"
                                                            :placeholder="dettagli.parti[16].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                                            item-value="iscritto.id">
                                                <template v-slot:selection="data">
                                                    <span>
                                                        {{ data.item.iscritto.nominativo }}
                                                    </span>
                                                </template>
                                                <template v-slot:item="data">
                                                    <template>
                                                        <v-list-item-content>
                                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>                                    
                                </v-card-text>
                            </v-card>
                         </v-tab-item>
                        <v-tab-item>
                            <v-textarea v-model="htmlJW"
                                        class="ma-2"
                                        dense
                                        outlined
                                        placeholder="Copiare il sorgente pagina HTML dalla guida JW e incollarlo in questo campo.
Successivamente utilizzare il tasto 'ESTRAI MATERIALE' per estrarre il materiale."
                                        >
                            </v-textarea>
                        </v-tab-item>
                    </v-tabs-items>
                    <v-row>
                        <v-col cols="2">
                            <v-text-field v-model="dettagli.materiale[9].descrizione"
                                          outlined
                                          hide-details
                                          label="Cantico"
                                          dense>
                            </v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="3">
                            <v-autocomplete v-model="dettagli.parti[17].parte.iscritto.id"
                                            :items="dettagli.parti[17].iscrittiEstesi"
                                            chips
                                            no-data-text="Nessun iscritto trovato"
                                            dense
                                            outlined
                                            clearable
                                            hide-details
                                            :label="dettagli.parti[17].parte.tipoParte.descrizione"
                                            :placeholder="dettagli.parti[17].parte.tipoParte.descrizione" item-text="iscritto.nominativo"
                                            item-value="iscritto.id">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ data.item.iscritto.nominativo }}
                                    </span>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title :class="'text-subtitle-1 font-weight-regular '+(data.item.iscritto.isOut ? 'red--text':'')">{{data.item.iscritto.nominativo}}</v-list-item-title>
                                            <v-list-item-subtitle v-if="data.item.ultimaParte" class="text-caption font-weight-regular" v-html="'Ultima parte: ' + data.item.ultimaParte.tipoParte.descrizione+' ('+localData(data.item.ultimaParte.data)+') ('+data.item.numeroParti+')'"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="modificheAbilitate" :disabled="!valid" color="error" @click="save">Salva</v-btn>
                <v-btn color="warning" :loading="loading" @click.stop="dialog = false">Chiudi</v-btn>
                <v-btn color="primary" :href='linkJW' target="_blank">Guida JW</v-btn>
                <v-btn color="pink" dark @click="scrapeJW">Estrai Materiale</v-btn>
                <v-btn v-if="!fileDoc" color="success" :loading="loadingDoc" @click="generaDoc">Genera Programma</v-btn>
                <v-btn v-if="fileDoc" color="success" @click="downloadDoc">Scarica Programma</v-btn>
                <v-btn v-if="fileDoc" color="success" @click="linkViewerDocument">Apri Programma</v-btn>                
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackInfo"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Credenziali copiate in memoria.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-dialog>
</template>

<script>
    import {STD_PASSWORD, SUPERADMIN_USERNAME, callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack} from '@/modules/utilities.js'

    import { bus } from '@/main.js'

    export default {
        data() {
            return {
                dialog: false,
                programma: {},
                sesso: '0',
                valid: false,   
                snackInfo: false,
                snackSuccess: false,
                snackError: false,
                snackCancel: false,
                snackRemote: false,
                SUPERADMIN_USERNAME: '',
                loading:false,
                dettagli: {},
                message:'',
                data:null,
                tab:null,
                linkJW:'',
                htmlJW:'',
                fileDoc:'',
                fileAss:'',
                loadingDoc: false,
                linkStaticGoogle: 'https://docs.google.com/viewerng/viewer?url=https://board.talkapp.it/',
                linkStaticMicrosoft: 'https://view.officeapps.live.com/op/embed.aspx?src=https://board.talkapp.it/',
                viewAss0: true,
                viewAss1: true,
                viewAss2: true,
                viewAss3: true,
                viewAss4: true,
                viewAss5: true,
                viewAss6: true,
                viewAss7: true,
                viewAss8: true,
                viewAss9: true,
            }
        },
        methods: {
            async save() {
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.iscritto.username,
                    token: this.$store.state.iscritto.token,
                    idCongregazione: this.$store.state.congregazioneCorrente.partitionReference,
                    tag: "VITA",
                    programma: this.programma,
                    materiale: this.dettagli.materiale,
                    parti: this.dettagli.parti
                };
                let programma = await callPost('api/programma/save', paramJson);                

                if (programma) this.snackSuccess = true;
                else this.snackError = true;

                if(programma) {

                    bus.$emit('dialog-update-vita', programma);
                   
                    //ricarico

                    bus.$emit('dialog-show-vita',programma);
                }                
            },                
            filterFunction(item, queryText) {
                return (                    
                    (item.diId+"").toLowerCase().includes(queryText.toLowerCase()) ||  
                    item.tema.toLowerCase().includes(queryText.toLowerCase())
                  );
            },           
            clickElimina(item) {
                if(item.tipoModifica==0) {
                    item.tipoModifica=2;
                }
                else if(item.tipoModifica==1) {
                    this.discorsi.splice(this.discorsi.findIndex(d=>d.diId==item.diId));
                }
                else if(item.tipoModifica==2) {
                    item.tipoModifica=0;
                }
                if (this.page > Math.ceil(this.discorsi.length / 5)) {
                    this.page = Math.ceil(this.discorsi.length / 5);
                }
            },
            changeSesso() {
                this.iscritto.isUomo = (this.sesso=='0');
                this.iscritto.isDonna = (this.sesso=='1');
            },
            async checkUsername() {
                
                let param = [this.$store.state.congregazioneCorrente.partitionReference, this.iscritto.id, this.iscritto.username];
                let risposta = await callService("api/iscritto/checkUsername", param);
                this.message = risposta ? "" : "0";
                this.$refs.formVita.validate();
            },
            dataCalendar(v) {
                return gDataCalendar(v);
            },
            localData(v) {
                return gLocalData(v);
            },
            async changeTipo(tag,pos) {
                let param = [this.$store.state.congregazioneCorrente.partitionReference, this.programma.id,"VITA", tag, pos];
                let dettagli = await callService("api/programma/dettagli", param);
                
                this.dettagli.parti[pos].iscrittiEstesi = dettagli.parti[0].iscrittiEstesi;
                this.dettagli.parti[pos].parte.tipoParte = dettagli.parti[0].parte.tipoParte;

                await this.changeTipoII(tag, pos+14);
            },
            async changeTipoII(tag, pos) {
                let param = [this.$store.state.congregazioneCorrente.partitionReference, this.programma.id, "VITA", tag, pos];
                let dettagli = await callService("api/programma/dettagli", param);

                this.dettagli.parti[pos].iscrittiEstesi = dettagli.parti[0].iscrittiEstesi;
                this.dettagli.parti[pos].parte.tipoParte = dettagli.parti[0].parte.tipoParte;
            },
            async updateJW() {
                let param = [this.$store.state.congregazioneCorrente.partitionReference, this.programma.data];
                let output = await callService("api/jw/retriever", param);
                this.linkJW=output.link;
            },
            async scrapeJW() {
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.iscritto.username,
                    token: this.$store.state.iscritto.token,
                    idCongregazione: this.$store.state.congregazioneCorrente.partitionReference,
                    idProgramma: this.programma.id,
                    htmlJW: this.htmlJW                    
                };
                let output = await callPost("api/jw/scraper", paramJson);

                if(output) this.snackSuccess = true;
                else this.snackError = true;

                if(output) {
                    this.dettagli.materiale = output.materiale;
                }
            },
            async generaDoc() {
                this.loadingDoc=true;
                let param = [this.$store.state.congregazioneCorrente.partitionReference, this.programma.id];
                let output = await callService("api/doc/vitaDoc", param);
                if(output) {
                    this.fileDoc = output.fileName;
                }
                else this.snackError=true;
                this.loadingDoc=false;
            },
            downloadDoc(){
                window.open(this.fileDoc,'_blank');
                this.fileDoc=''; 
            },
            linkViewerDocument(){
                window.open(this.linkStaticGoogle + this.fileDoc, '_blank');
                this.fileDoc='';                
            },
            printPdf1(n) {
                this.viewAss0 = !this.viewAss0;
            },
            async printPdf(n) {
                this.resetViewAss(n);
                if(this.getViewAss(n)) {
                    let pos =  n==0 ? 4 :
                             ( n==1 ? 5 :
                             ( n==2 ? 7 :
                             ( n==3 ? 9 :
                             ( n==4 ? 11 :
                             ( n==5 ? 27 :
                             ( n==6 ? 19 :
                             ( n==7 ? 21 :
                             ( n==8 ? 23 : 25 ))))))));

                    if(this.dettagli.parti[pos].parte.iscritto.id==0) return;
                    let param = [this.$store.state.congregazioneCorrente.partitionReference, this.programma.id, pos];
                    let output = await callService("api/pdf/assegnazione", param);
                    if(output) {
                        this.fileAss = output.fileName;
                        this.setViewAss(n,false);
                    }
                    else {
                        this.snackError=true;                        
                    }
                }
                else {
                    window.open(this.fileAss,'_blank');
                    this.fileAss=''; 
                    this.setViewAss(n,true);
                }                
            },
            resetViewAss(n) {
                for(let i=0;i<=9;i++) {
                    if(i==n) continue;
                    this.setViewAss(i,true);
                }
            },
            getViewAss(n) {
                if(n==0) return this.viewAss0;
                if(n==1) return this.viewAss1;
                if(n==2) return this.viewAss2;
                if(n==3) return this.viewAss3;
                if(n==4) return this.viewAss4;
                if(n==5) return this.viewAss5;
                if(n==6) return this.viewAss6;
                if(n==7) return this.viewAss7;
                if(n==8) return this.viewAss8;
                if(n==9) return this.viewAss9;
            },
            setViewAss(n,v) {
                if(n==0) this.viewAss0 = v;
                if(n==1) this.viewAss1 = v;
                if(n==2) this.viewAss2 = v;
                if(n==3) this.viewAss3 = v;
                if(n==4) this.viewAss4 = v;
                if(n==5) this.viewAss5 = v;
                if(n==6) this.viewAss6 = v;
                if(n==7) this.viewAss7 = v;
                if(n==8) this.viewAss8 = v;
                if(n==9) this.viewAss9 = v;
            }
        
        },
        computed: {     
            modificheAbilitate() {
                if(this.programma.id == "0") return true;
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                else if(this.$store.state.iscritto.isAdmin) b=true;

                return b;
            },
            isAdmin() {
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                else if(this.$store.state.iscritto.isAdmin) b = true;

                return b;
            },
            isSuperAdmin() {
                let b=false;
                if(this.$store.state.iscritto.username==this.SUPERADMIN_USERNAME) b = true;
                return b;
            }
        },
        async created() {
            this.SUPERADMIN_USERNAME = SUPERADMIN_USERNAME;

            var vm = this;
            bus.$on('dialog-show-vita', async function (programma) {

                vm.dialog = true;
                vm.programma = programma;
                vm.loading = true;

                if (programma.id == '0') programma.data = new Date();
                else programma.data = vm.dataCalendar(programma.data);

                let param = [vm.$store.state.congregazioneCorrente.partitionReference, programma.id,"VITA"];
                vm.dettagli = await callService("api/programma/dettagli", param);

                vm.changeTipoII(vm.dettagli.parti[5].parte.tipoParte.tag, 19);
                vm.changeTipoII(vm.dettagli.parti[7].parte.tipoParte.tag, 21);
                vm.changeTipoII(vm.dettagli.parti[9].parte.tipoParte.tag, 23);
                vm.changeTipoII(vm.dettagli.parti[11].parte.tipoParte.tag, 25);

                param = [vm.$store.state.congregazioneCorrente.partitionReference, programma.data];
                let output = await callService("api/jw/retriever", param);
                vm.linkJW=output.link;
                vm.fileDoc ='';
                vm.fileAss ='';
                vm.htmlJW ='';
                vm.loading = false;

                vm.$nextTick(() => {
                    if(!vm.$refs.formIscritto) return;
                    vm.$refs.formProgramma.resetValidation();
                });
            });            
        },        
        beforeDestroy() {
            bus.$off('dialog-show-vita');
        }
    }
</script>