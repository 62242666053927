<template>
    <v-container>
        <template>
            <v-data-table :loading="loadingGruppi"
                            :headers="headersGruppi"
                            :items="gruppi"
                            :items-per-page="8"
                            loading-text="Caricamento dati..."
                            no-data-text="Nessuno gruppo presente"
                            no-results-text="Nessun gruppo corrispondente ai criteri di ricerca"
                            :search="search"
                            :footer-props="{
                    'items-per-page-text':'Pagina da',
                    'items-per-page-all-text': 'Tutti',
                    'items-per-page-options':[5,8,10,20,-1]
                    }"
                            class="elevation-1">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title><b>Gruppi</b></v-toolbar-title>
                        <v-col cols="5">
                            <v-text-field class="mt-6 ml-4"
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Ricerca un gruppo..."
                                            single-line
                                            outlined
                                            clearable
                                            dense>
                            </v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-tooltip v-if="isAdmin" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon x-large
                                        color="info"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="nuovoGruppo">
                                    <v-icon>mdi-view-grid-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Nuovo Gruppo</span>
                        </v-tooltip>
                    </v-toolbar>
                </template>               
                <template v-slot:item.dettaglio="{ item }">
                    <div>
                        <v-icon @click="clickGruppo(item)">mdi-magnify</v-icon>
                    </div>
                </template>
            </v-data-table>           


            <gruppo-dialog v-model="dialogGruppo" editGruppo="gruppo" />

        </template>
    </v-container>
</template>

<script>

    import {callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, Snack, SUPERADMIN_USERNAME} from '@/modules/utilities.js'
    import GruppoDialog from '@/views/components/GruppoDialog.vue'
    import { bus } from '@/main.js'

    export default {
        components:{
            GruppoDialog
        },
        data: () => ({            
            headersGruppi: [
                {text: 'Nome Gruppo', align: 'start', value: 'nome'},
                { text: '', value: 'dettaglio', sortable: false },
            ],
            gruppi: [],
            dialogGruppo: false,
            gruppo: {},
            loadingGruppi:false,
            SUPERADMIN_USERNAME: '',
            isAdmin: false,
            search: ''  
        }),
        computed: {
        },
        methods: {
            async clickGruppo(gruppo) {    
                this.gruppo=JSON.parse(JSON.stringify(gruppo));
                let param=[this.$store.state.idCongregazione,this.gruppo.id];
                bus.$emit('dialog-show-gruppo', this.gruppo);
            },
            async onUpdateGruppo(gruppo) {
                const i = this.gruppi.findIndex(o => o.id == gruppo.id);
                if (i>=0)   {
                    this.gruppi.splice(i, 1, gruppo);
                }
                else {
                    this.gruppi.push(gruppo);
                }
            },
            nuovoGruppo() {
                this.gruppo={ id:"0", isUomo: true };
                bus.$emit('dialog-show-gruppo', this.gruppo);
            }
        },
        async created() {
            this.loadingGruppi = true;
            let param = [this.$store.state.congregazioneCorrente.partitionReference];
            this.gruppi = await callService("api/iscritto/listaGruppi", param);
            this.loadingGruppi = false;
            this.SUPERADMIN_USERNAME = SUPERADMIN_USERNAME;
            this.isAdmin =this.$store.state.iscritto.username==SUPERADMIN_USERNAME || this.$store.state.iscritto.isAdmin;
            bus.$on('dialog-update-gruppo', this.onUpdateGruppo)
        },
        beforeDestroy() {
            bus.$off('dialog-update-gruppo', this.onUpdateGruppo)
        }
        
    }</script>
